<template>
  <esmp-modal
    v-model="isShowed"
    title="Связать с группой"
    width="500"
  >
    <p class="body-2 mt-2 mb-0">
      Связанные группы
    </p>
    <esmp-checkbox-group v-model="connectedGroups">
      <esmp-checkbox
        v-for="group in groups"
        class="mt-1"
        :label="group.techId"
        :key="group.techId"
      >
        {{ group.name }}
      </esmp-checkbox>
    </esmp-checkbox-group>
    <template #footer>
      <esmp-button @click="closeHandler" view="outline">
        Отмена
      </esmp-button>
      <esmp-button @click="submitHandler">
        Применить
      </esmp-button>
    </template>
  </esmp-modal>
</template>

<script>
export default {
  name: 'ModalCmdbKeConnectGroup',
  model: {
    prop: 'showed',
    event: 'input',
  },
  props: {
    showed: Boolean,
    item: {
      type: Object,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      connectedGroups: [],
    };
  },
  computed: {
    isShowed: {
      get() {
        return this.showed;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    submitHandler() {
      this.$emit('set', this.connectedGroups);
      this.closeHandler();
    },
    closeHandler() {
      this.$emit('input', false);
    },
  },
  watch: {
    showed(value) {
      this.connectedGroups = value ? this.item.connectedGroups || [] : [];
    },
  },
};
</script>
